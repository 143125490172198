.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  width: auto;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
}

.modal-overlay,
.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content label {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: black;
  margin-top: 1rem;
  display: block;
}

.modal-content input,
.modal-content button[type='submit'],
.modal-content button[type='button'] {
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  border: 2px solid black;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.modal-content button[type='submit'],
.modal-content button[type='button'] {
  cursor: pointer;
  background: black;
}

.modal-content button[type='submit']:disabled,
.modal-content button[type='button']:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

/* Media Queries for Mobile */
@media only screen and (max-width: 767px) {

  .modal-content label,
  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    font-size: 12px;
    line-height: 4px;
  }

  .form-group {
    width: 270px;
  }

  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    width: 100%;
    min-height: 30px;
    font-family: var(--font-family);
    font-weight: 400;
    border: 2px solid black;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    margin-top: 0.5rem;
  }
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    cursor: pointer;
    background: black;
  }
  textarea {
    width: 100%;
    margin-top: 10px;
  }
}


/* Media Queries for Tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modal-content {
    width: -webkit-fill-available;
  }


  textarea {
    width: 100%;
    margin-top: 10px;
  }

  .modal-content label,
  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    font-size: 12px;
    line-height: 6px;
  }

  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    width: 100%;
    min-height: 30px;
    font-family: var(--font-family);
    font-weight: 400;
    border: 2px solid black;
    padding: 0 1rem;
    color: #fff;
    outline: none;
    border-radius: 5px;
    margin-top: 1.5rem;
  }
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    cursor: pointer;
    background: black;
  }

  .form-group {
    width: 270px;
  }
}