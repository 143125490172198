.cardCounter {
    width: 100%;
    max-width: 300px;
    background-color: rgb(4, 12, 24);
    border-radius: 10px;
    box-shadow: 1px 5px 10px white;
    transition: box-shadow 0.3s ease;
    margin-right: 20px;
    margin-bottom: 20px;
}

.cardCounter:hover {
    box-shadow: 0 4px 8px white;
    background: linear-gradient(0.22deg,black 50.14%, gray 135.55%,transparent);
}

.card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    padding: 10% 35%;
}

.card-caption {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-title {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.card-description {
    color: white;
    margin-top: 10px;
    font-family: "Nunito", Sans-serif;
    font-size: 30px;
}

.card_container {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
    .cardCounter {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        display: flex;
        background-color: rgb(4, 12, 24);
        border-radius: 10px;
        box-shadow: 1px 5px 10px white;
    }

    .card-image {
        width: 50%;
        height: auto;
        border-radius: 10px;
        padding: 15% 15%;
    }

    .card-caption {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-description {
        margin-top: 10px;
        font-family: "Nunito", Sans-serif;
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {

    .card-image {
        width: 50%;
        height: auto;
        border-radius: 10px;
        padding: 24% 9%;
    }
    .card-description {
        font-size: 11px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .cardCounter {
        max-width: 45%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-right: 20px;
        display: flex;
    }

    .card-image {
        width: 50%;
        height: auto;
        border-radius: 10px;
        padding: 10% 10%;
    }

    .card-caption {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-description {
        margin-top: 10px;
        font-family: "Nunito", Sans-serif;
        font-size: 15px;
    }
}