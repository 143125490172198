/* Default styles */

.horizontal-row {
    display: flex;
    justify-content: center;
}

.left-side,
.right-side {
    height: 100%;
    width: 50%;
    padding: 30px;
}

.left-side {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    justify-items: center;
    align-items: center;
}

.google-map {
    height: 300px;
    width: 600px;
}

iframe {
    height: 300px;
    width: 600px;
}

.query {
    display: flex;
}
.gpt3__mapRow-heading {
    padding-left: 1rem;
}
h2 {
    /* color: #020202; */
    font-family: "Work Sans", Sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.2em;
}

.contact-text {
    color: white;
}

.contacts-section {
    width: 550px;
    height: 230px;
    color: #fff;
    line-height: 35px;
}

.contactInfoRow {
    width: 100%;
    display: flex;
}

.contact-info-left {
    width: 50%;
}

.contact-info-right {
    width: 50%;
}

.section-heading {
    margin-bottom: 30px;
    color: white;
}

.fa,
.far,
.fas {
    font-family: "Font Awesome 5 Free";
    margin-right: 10px;
}

/* Styles for screens with width 1024px and above */
@media (min-width: 1024px) {
    .horizontal-row {
        flex-direction: row;
    }

    .google-map,
    iframe {
        height: 250px;
        width: 500px;
    }

    .contacts-section {
        width: 500px;
        height: auto;
        padding: 20px;
        line-height: 30px;
    }

    .contactInfoRow {
        flex-direction: row;
    }

    .contact-info-left,
    .contact-info-right {
        width: 50%;
    }
}

/* Styles for tablet screens */
@media (min-width: 768px) and (max-width: 1023px) {

    .google-map,
    iframe {
        height: 250px;
        width: 100%;
    }

    .contacts-section {
        width: 100%;
        height: auto;
        padding: 20px;
        line-height: 30px;
    }

    .contactInfoRow {
        flex-direction: column;
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%;
    }
}

/* Styles for mobile screens */
@media (max-width: 767px) {
    .horizontal-row {
        flex-direction: column;
    }

    .left-side,
    .right-side {
        width: 100%;
    }

    .google-map,
    iframe {
        height: 200px;
        width: 100%;
    }

    .contacts-section {
        width: 100%;
        height: auto;
        padding: 20px;
        line-height: 30px;
    }

    .contactInfoRow {
        flex-direction: column;
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%;
    }
}