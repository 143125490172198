::-webkit-scrollbar {
    width: 1.3rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #797979;
    transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background: #222224;
}

::-webkit-scrollbar-track {
    background: #f9f9f9;
}

.container {
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
    color: white;
}

.heading {
    padding: 1rem 0;
    font-size: 2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper_container {
    height: 35rem;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    width: 37rem;
    height: 42rem;
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        height: 30rem;
    }

    .swiper-slide {
        width: 28rem !important;
        height: 36rem !important;
    }

    .swiper-slide img {
        width: 150px !important;
        height: 150px !important;
    }
}

.swiper-slide img {
    width: 300px;
    height: 300px;
    border-radius: 2rem;
    object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 61% !important;
    transform: translateX(-61%) !important;
}

.card_container {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 73% !important;
        transform: translateX(-73%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 83% !important;
        transform: translateX(-83%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 27% !important;
        transform: translateX(-27%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 17% !important;
        transform: translateX(-17%) !important;
    }
}

.slider-controler .slider-arrow {
    background: white;
    padding: 10px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left: 39%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: auto !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px white);
    opacity: 3;
    background: white;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: rgb(0, 181, 154);
    opacity: 3;
}

.swiper-3d {
    perspective: 1200px !important;
}


.swiper-wrapper { 
    margin-left: 10rem;
}

@media screen and (max-width: 767px) {
    .swiper-3d {
        perspective: 70px !important;
    }
    .swiper-wrapper { 
        height: 70%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .swiper-3d {
        perspective: 170px !important;
    }
}

@media screen and (min-width: 1024px) {
    .swiper-3d {
        perspective: 500px !important;
    }
}