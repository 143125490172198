
.query-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.query-content {
  width: auto;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
}

.query-content h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.query-content label {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: black;
  margin-top: 1rem;
  display: block;
}

.query-content input,
.query-content select {
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  border: 2px solid black;
  padding: 0 1rem;
  outline: none;
  color: black;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.query-content button[type='submit'],
.query-content button[type='button'] {
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: black;
  border: 2px solid black;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-top: 1.5rem;
}

.query-overlay,
.query-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

/* Media Queries for Mobile */
@media only screen and (max-width: 767px) {
.query-content {
  width: -webkit-fill-available;
}

.query-content textarea{
  width: -webkit-fill-available;
  padding: 2px 5px;
}

.query-content h2 {
  font-size: 32px;
  line-height: 42px;
}
.query-overlay,
.query-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}


.query-content label,
.query-content input,
.query-content select,
.query-content button[type='submit'],
.query-content button[type='button'] {
  font-size: 16px;
  line-height: 24px;
}
}

/* Media Queries for Tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
.query-content {
  width: -webkit-fill-available;
}
.query-content textarea{
  width: -webkit-fill-available;
  padding: 2px 5px;
}
.query-content h2 {
  font-size: 42px;
  line-height: 55px;
}
.query-overlay,
.query-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.query-content label,
.query-content input,
.query-content select,
.query-content button[type='submit'],
.query-content button[type='button'] {
  font-size: 18px;
  line-height: 26px;
}
}
.query-content button[type='submit']:disabled,
.query-content button[type='button']:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}