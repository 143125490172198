@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
.gpt3__blog-container_article {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.gpt3__blog-container_article-image {
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.gpt3__blog-container_article-image img {
    width: 100%;
    height: 100%;
}

.gpt3__blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.gpt3__blog-container_article-content p, .gpt3__blog-container_article-content time {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;

    color: #fff;
}

.gpt3__blog-container_article-content h2 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #fff;
    margin-bottom: 5rem;
    cursor: pointer;
}

.gpt3__blog-container_article-content p:last-child {
    cursor: pointer;
}


@media screen and (max-width: 550px) {
    .gpt3__blog-container_article-content h2 {
        font-size: 18px;
        line-height: 25p;
    }
}

.gpt3__blog-container_article-image-inner {
    justify-content: center;
    align-items: center;
    padding-bottom: 75%; /* Set the desired aspect ratio (e.g., 4:3 = 75%) */
    position: relative;
  }
  
  .gpt3__blog-container_article-image-inner img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.gpt3__blog {
    display: flex;
    flex-direction: column;    
}

.gpt3__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.gpt3__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.gpt3__blog-container {
    display: flex;
    flex-direction: row;
}

.gpt3__blog-container_groupA {
    flex: 0.75 1;
    margin-right: 2rem;
}

.gpt3__blog-container_groupB {
    flex: 1 1;

    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

@media screen and (max-width: 990px) {
    .gpt3__blog-container {
        flex-direction: column-reverse;
    }


    .gpt3__blog-container_groupA {
        margin: 2rem 0;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__blog-container_groupB {
        grid-template-columns: repeat(1, 1fr);
    }

    .gpt3__blog-container_groupA .gpt3__blog-container_article  {
       width: 100%;
    }

    .gpt3__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__blog-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

}
.gpt3__features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;

    margin: 1rem;
}

.gpt3__features-container__feature-title {
    flex: 1 1;
    max-width: 180px;
    margin-right: 2rem;
}

.gpt3__features-container__feature-title h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
}

.gpt3__features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: var(--gradient-bar);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 0.25rem;
}

.gpt3__features-container_feature-text {
    flex: 2 1;
    max-width: 390px;
    display: flex;
}

.gpt3__features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

@media screen and (max-width: 550px) {
    .gpt3__features-container__feature-title h2 {
        font-size: 14px;
        line-height: 22px;
    }

    .gpt3__features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .gpt3__features-container__feature {
        margin: 1rem 0;
    }
}
.gpt3__features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.gpt3__features-heading {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.gpt3__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.gpt3__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.gpt3__features-container {
    flex: 1.5 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .gpt3__features {
        flex-direction: column;
    }

    .gpt3__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .gpt3__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}
.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-footer);
  }
  
  .gpt3__footer-heading {
    width: 100%;
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .gpt3__footer-heading h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 64px;
    line-height: 75px;
  }
  
  .gpt3__footer-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 1px solid #fff;
    text-align: center;
    margin-bottom: 10rem;
    cursor: pointer;
  }
  
  .gpt3__footer-btn p {
    font-size: 18px;
    font-family: var(--font-family);
    line-height: 21px;
    color: #fff;
    word-spacing: 2px;
  }
  
  .gpt3__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
  }
  
  .gpt3__footer-links_logo {
    display: flex;
    flex-direction: column;
  }
  
  .gpt3__footer-links_logo img {
    width: 118px;
    height: 30px;
    margin-bottom: 1rem;
  }
  
  .gpt3__footer-links_logo p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 15px;
    color: #fff;
  }
  
  .gpt3__footer-links_nav {
    display: flex;
    flex-direction: column;
  }
  
  .gpt3__footer-links_nav h3 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;
    margin-bottom: 0.9rem;
  }
  
  .gpt3__footer-links_nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .gpt3__footer-links_nav li {
    margin-bottom: 0.5rem;
  }
  
  .gpt3__footer-links_nav li a, .gpt3__footer-links_nav li div{
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;
    cursor: pointer;
  }
  
  .gpt3__footer-links_div {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  
  .gpt3__footer-links_div h3 {
    font-size: 14px;
    line-height: 17px;
    font-family: var(--font-family);
    color: #fff;
    margin-bottom: 0.9rem;
  }
  
  .gpt3__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;
    margin: 0.5rem 0;
    cursor: pointer;
  }
  
  .gpt3__footer-links_div address {
    font-size: 12px;
    line-height: 15px;
    font-family: var(--font-family);
    color: #fff;
  }
  
  .gpt3__footer-links_div address p {
    margin: 0.5rem 0;
  }
  
  .gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
  }
  
  .gpt3__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color: #fff;
  }
  
  @media screen and (max-width: 850px) {
    .gpt3__footer-heading h2 {
      font-size: 44px;
      line-height: 50px;
    }
  }
  
  @media screen and (max-width: 550px) {
    .gpt3__footer-heading h2 {
      font-size: 34px;
      line-height: 42px;
    }
  
    .gpt3__footer-links div {
      margin: 1rem 0;
    }
  
    .gpt3__footer-btn p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  
  @media screen and (max-width: 400px) {
    .gpt3__footer-heading h2 {
      font-size: 27px;
      line-height: 38px;
    }
  }
  .p-dialog .p-dialog-content {
    background: black;
    color: white;
    padding: 0 1.5rem 2rem;
    font-size: 12px;
    line-height: 20px;
    text-align: justify;
}

.p-dialog .p-dialog-header {
  background: var(--gradient-bar);
  color: black;
  padding: 0.7rem;
}

.p-accordion .p-accordion-tab {
  margin-top: 10px;
}

.p-accordion .p-accordion-content {
  background: black;
  color: white;
}

.gpt3__header {
  display: flex;
  flex-direction: row;
}

.gpt3__header-content {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 5rem;
}

.gpt3__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.gpt3__header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  margin-top: 1.5rem;
}

.gpt3__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: row;
}

.gpt3__header-content__input input {
  flex: 2 1;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: var(--color-footer);
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
  flex: 0.6 1;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: #ad1c00;
  border: 2px solid #ad1c00;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 2rem;
}

.gpt3__header-content__people img {
  width: 181.79px;
  height: 38px;
}

.gpt3__header-content__people p {
  margin: 0 0 0 1rem;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}

.gpt3__header-image {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gpt3__header-image img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1050px) {
  .gpt3__header {
    flex-direction: column;
  }

  .gpt3__header-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .gpt3__header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }

  .gpt3__header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .gpt3__header-content__people {
    flex-direction: column;
  }

  .gpt3__header-content__people p {
    margin: 0;
  }

  .gpt3__header-content__input input,
  .gpt3__header-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
}

@media screen and (max-width: 490px) {
  .gpt3__header-content h1 {
    font-size: 36px;
    line-height: 48px;
  }

  .gpt3__header-content p {
    font-size: 14px;
    line-height: 24px;
  }

  .gpt3__header-content__input input,
  .gpt3__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
}

.underConstruction {
  color: white;
  display: flex;
  justify-content: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  width: auto;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
}

.modal-overlay,
.modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content label {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: black;
  margin-top: 1rem;
  display: block;
}

.modal-content input,
.modal-content button[type='submit'],
.modal-content button[type='button'] {
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  border: 2px solid black;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.modal-content button[type='submit'],
.modal-content button[type='button'] {
  cursor: pointer;
  background: black;
}

.modal-content button[type='submit']:disabled,
.modal-content button[type='button']:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}

/* Media Queries for Mobile */
@media only screen and (max-width: 767px) {

  .modal-content label,
  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    font-size: 12px;
    line-height: 4px;
  }

  .form-group {
    width: 270px;
  }

  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    width: 100%;
    min-height: 30px;
    font-family: var(--font-family);
    font-weight: 400;
    border: 2px solid black;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    border-radius: 5px;
    margin-top: 0.5rem;
  }
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    cursor: pointer;
    background: black;
  }
  textarea {
    width: 100%;
    margin-top: 10px;
  }
}


/* Media Queries for Tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modal-content {
    width: -webkit-fill-available;
  }


  textarea {
    width: 100%;
    margin-top: 10px;
  }

  .modal-content label,
  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    font-size: 12px;
    line-height: 6px;
  }

  .modal-content input,
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    width: 100%;
    min-height: 30px;
    font-family: var(--font-family);
    font-weight: 400;
    border: 2px solid black;
    padding: 0 1rem;
    color: #fff;
    outline: none;
    border-radius: 5px;
    margin-top: 1.5rem;
  }
  .modal-content button[type='submit'],
  .modal-content button[type='button'] {
    cursor: pointer;
    background: black;
  }

  .form-group {
    width: 270px;
  }
}
.gpt3__possibility {
    display: flex;
    flex-direction: row;
}

.gpt3__possibility-image {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    margin-right: 2rem;
}

.gpt3__possibility-image img {
    width: 100%;
    height: 100%;
}

.gpt3__possibility-content {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.gpt3__possibility-content h2 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #71E5FF;
}

.gpt3__possibility-content h2:last-child {
    color: #ff8A71;
}

.gpt3__possibility-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;
    line-height: 45px;
    margin: 1rem 0;
}

.gpt3__possibility-content p {
    color: var(--color-text);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;

    margin-bottom: 2rem;
}

@media screen and (max-width: 950px) {
    .gpt3__possibility {
        flex-direction: column;
    }

    .gpt3__possibility-image {
        margin: 1rem 0;
    }

    .gpt3__possibility-image img {
        width: unset;
        height: unset;
    }

    .gpt3__possibility-content {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 700px) {
    .gpt3__possibility-image img {
        width: 100%;
        height: 100%;
    }
}


.gpt3__whatgpt3 {
    display: flex;
    flex-direction: column;

    padding: 2rem;
    background: var(--color-footer);

    /* ff 3.6+ */

    /* safari 5.1+,chrome 10+ */

    /* opera 11.10+ */

    /* ie 10+ */

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gpt3__whatgpt3-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
    max-width: 700px;
}

.gpt3__whatgpt3-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.gpt3__whatgpt3-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.gpt3__whatgpt3-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.gpt3__whatgpt3-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container__feature {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.gpt3__whatgpt3-container .gpt3__features-container_feature-text {
    margin-top: 0.5rem;
}

@media screen and (max-width: 850px) {
    .gpt3__whatgpt3-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .gpt3__whatgpt3-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-feature .gpt3__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .gpt3__whatgpt3-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .gpt3__whatgpt3-container .gpt3__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}
.gpt3__brand {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.gpt3__brand div {
    flex: 1 1;
    max-width: 150px;
    min-width: 120px;
    margin: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.gpt3__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.gpt3__cta-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.gpt3__cta-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.gpt3__cta-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.gpt3__cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.gpt3__cta-btn button {
    background: #000;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 650px) {
    .gpt3__cta {
        flex-direction: column;
    }

    .gpt3__cta-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__cta {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .gpt3__cta-content h3 {
        font-size: 18px;
        line-height: 32px;
    }

    .gpt3__cta-btn button {
        font-size: 14px;
        line-height: 28px;
    }
}

.gpt3__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 2rem 6rem;
}

.gpt3__navbar-links {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.gpt3__navbar-links_logo {
    margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.gpt3__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.gpt3__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: #AD1C00;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.gpt3__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.gpt3__navbar-menu svg {
    cursor: pointer;
}

.gpt3__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.gpt3__navbar-menu_container p {
    margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1023px) {
    .gpt3__navbar-links_container {
        display: none;
    }

    .gpt3__navbar-menu {
        display: flex;
    }

    .gpt3__navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 2rem;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1145px) {
    .gpt3__navbar-links_container p,
    .gpt3__navbar-sign p,
    .gpt3__navbar-menu_container p {
        font-size: 12px;
    }
}
@media screen and (min-width: 1146px) and (max-width: 1440px) {
    .gpt3__navbar-links_container p,
    .gpt3__navbar-sign p,
    .gpt3__navbar-menu_container p {
        font-size: 16px;    
    }
}

@media screen and (max-width: 700px) {
    .gpt3__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__navbar {
        padding: 2rem;
    }

    .gpt3__navbar-sign {
        display: none;
    }

    .gpt3__navbar-menu_container {
        top: 20px;
    }

    .gpt3__navbar-menu_container-links-sign {
        display: block;
    }
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__bg {

  /* safari 5.1+,chrome 10+ */

  /* opera 11.10+ */

  /* ie 10+ */

  /* global 92%+ browsers support */
  background:radial-gradient(circle at 3% 25%, rgba(0, 40, 83, 1) 0%, rgba(4, 12, 24, 1) 25%);
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
  }  
}

.query-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.query-content {
  width: auto;
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
}

.query-content h2 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
}

.query-content label {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: black;
  margin-top: 1rem;
  display: block;
}

.query-content input,
.query-content select {
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  border: 2px solid black;
  padding: 0 1rem;
  outline: none;
  color: black;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.query-content button[type='submit'],
.query-content button[type='button'] {
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  background: black;
  border: 2px solid black;
  padding: 0 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 5px;
  margin-top: 1.5rem;
}

.query-overlay,
.query-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

/* Media Queries for Mobile */
@media only screen and (max-width: 767px) {
.query-content {
  width: -webkit-fill-available;
}

.query-content textarea{
  width: -webkit-fill-available;
  padding: 2px 5px;
}

.query-content h2 {
  font-size: 32px;
  line-height: 42px;
}
.query-overlay,
.query-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}


.query-content label,
.query-content input,
.query-content select,
.query-content button[type='submit'],
.query-content button[type='button'] {
  font-size: 16px;
  line-height: 24px;
}
}

/* Media Queries for Tablets */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
.query-content {
  width: -webkit-fill-available;
}
.query-content textarea{
  width: -webkit-fill-available;
  padding: 2px 5px;
}
.query-content h2 {
  font-size: 42px;
  line-height: 55px;
}
.query-overlay,
.query-content {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.query-content label,
.query-content input,
.query-content select,
.query-content button[type='submit'],
.query-content button[type='button'] {
  font-size: 18px;
  line-height: 26px;
}
}
.query-content button[type='submit']:disabled,
.query-content button[type='button']:disabled {
  background: #ccc;
  border-color: #ccc;
  cursor: not-allowed;
}
/* Default styles */

.horizontal-row {
    display: flex;
    justify-content: center;
}

.left-side,
.right-side {
    height: 100%;
    width: 50%;
    padding: 30px;
}

.left-side {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    justify-items: center;
    align-items: center;
}

.google-map {
    height: 300px;
    width: 600px;
}

iframe {
    height: 300px;
    width: 600px;
}

.query {
    display: flex;
}
.gpt3__mapRow-heading {
    padding-left: 1rem;
}
h2 {
    /* color: #020202; */
    font-family: "Work Sans", Sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.2em;
}

.contact-text {
    color: white;
}

.contacts-section {
    width: 550px;
    height: 230px;
    color: #fff;
    line-height: 35px;
}

.contactInfoRow {
    width: 100%;
    display: flex;
}

.contact-info-left {
    width: 50%;
}

.contact-info-right {
    width: 50%;
}

.section-heading {
    margin-bottom: 30px;
    color: white;
}

.fa,
.far,
.fas {
    font-family: "Font Awesome 5 Free";
    margin-right: 10px;
}

/* Styles for screens with width 1024px and above */
@media (min-width: 1024px) {
    .horizontal-row {
        flex-direction: row;
    }

    .google-map,
    iframe {
        height: 250px;
        width: 500px;
    }

    .contacts-section {
        width: 500px;
        height: auto;
        padding: 20px;
        line-height: 30px;
    }

    .contactInfoRow {
        flex-direction: row;
    }

    .contact-info-left,
    .contact-info-right {
        width: 50%;
    }
}

/* Styles for tablet screens */
@media (min-width: 768px) and (max-width: 1023px) {

    .google-map,
    iframe {
        height: 250px;
        width: 100%;
    }

    .contacts-section {
        width: 100%;
        height: auto;
        padding: 20px;
        line-height: 30px;
    }

    .contactInfoRow {
        flex-direction: column;
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%;
    }
}

/* Styles for mobile screens */
@media (max-width: 767px) {
    .horizontal-row {
        flex-direction: column;
    }

    .left-side,
    .right-side {
        width: 100%;
    }

    .google-map,
    iframe {
        height: 200px;
        width: 100%;
    }

    .contacts-section {
        width: 100%;
        height: auto;
        padding: 20px;
        line-height: 30px;
    }

    .contactInfoRow {
        flex-direction: column;
    }

    .contact-info-left,
    .contact-info-right {
        width: 100%;
    }
}
::-webkit-scrollbar {
    width: 1.3rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #797979;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
    background: #222224;
}

::-webkit-scrollbar-track {
    background: #f9f9f9;
}

.container {
    max-width: 124rem;
    padding: 4rem 1rem;
    margin: 0 auto;
    color: white;
}

.heading {
    padding: 1rem 0;
    font-size: 2.5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper_container {
    height: 35rem;
    padding: 2rem 0;
    position: relative;
}

.swiper-slide {
    width: 37rem;
    height: 42rem;
    position: relative;
}

@media (max-width: 500px) {
    .swiper_container {
        height: 30rem;
    }

    .swiper-slide {
        width: 28rem !important;
        height: 36rem !important;
    }

    .swiper-slide img {
        width: 150px !important;
        height: 150px !important;
    }
}

.swiper-slide img {
    width: 300px;
    height: 300px;
    border-radius: 2rem;
    object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-controler .swiper-button-next {
    left: 61% !important;
    -webkit-transform: translateX(-61%) !important;
            transform: translateX(-61%) !important;
}

.card_container {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-next {
        left: 73% !important;
        -webkit-transform: translateX(-73%) !important;
                transform: translateX(-73%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-next {
        left: 83% !important;
        -webkit-transform: translateX(-83%) !important;
                transform: translateX(-83%) !important;
    }
}

@media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
        left: 27% !important;
        -webkit-transform: translateX(-27%) !important;
                transform: translateX(-27%) !important;
    }
}

@media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
        left: 17% !important;
        -webkit-transform: translateX(-17%) !important;
                transform: translateX(-17%) !important;
    }
}

.slider-controler .slider-arrow {
    background: white;
    padding: 10px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    left: 39%;
    -webkit-transform: translateX(-42%);
            transform: translateX(-42%);
    -webkit-filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
            filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
}

.slider-controler .slider-arrow::after {
    content: '';
}

.swiper-pagination {
    position: relative;
    width: auto !important;
    bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
    -webkit-filter: drop-shadow(0px 8px 24px white);
            filter: drop-shadow(0px 8px 24px white);
    opacity: 3;
    background: white;
}

.swiper-pagination .swiper-pagination-bullet-active {
    background: rgb(0, 181, 154);
    opacity: 3;
}

.swiper-3d {
    -webkit-perspective: 1200px !important;
            perspective: 1200px !important;
}


.swiper-wrapper { 
    margin-left: 10rem;
}

@media screen and (max-width: 767px) {
    .swiper-3d {
        -webkit-perspective: 70px !important;
                perspective: 70px !important;
    }
    .swiper-wrapper { 
        height: 70%;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .swiper-3d {
        -webkit-perspective: 170px !important;
                perspective: 170px !important;
    }
}

@media screen and (min-width: 1024px) {
    .swiper-3d {
        -webkit-perspective: 500px !important;
                perspective: 500px !important;
    }
}
.cardCounter {
    width: 100%;
    max-width: 300px;
    background-color: rgb(4, 12, 24);
    border-radius: 10px;
    box-shadow: 1px 5px 10px white;
    transition: box-shadow 0.3s ease;
    margin-right: 20px;
    margin-bottom: 20px;
}

.cardCounter:hover {
    box-shadow: 0 4px 8px white;
    background: linear-gradient(0.22deg,black 50.14%, gray 135.55%,transparent);
}

.card-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    padding: 10% 35%;
}

.card-caption {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-title {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.card-description {
    color: white;
    margin-top: 10px;
    font-family: "Nunito", Sans-serif;
    font-size: 30px;
}

.card_container {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
    .cardCounter {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        display: flex;
        background-color: rgb(4, 12, 24);
        border-radius: 10px;
        box-shadow: 1px 5px 10px white;
    }

    .card-image {
        width: 50%;
        height: auto;
        border-radius: 10px;
        padding: 15% 15%;
    }

    .card-caption {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-description {
        margin-top: 10px;
        font-family: "Nunito", Sans-serif;
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {

    .card-image {
        width: 50%;
        height: auto;
        border-radius: 10px;
        padding: 24% 9%;
    }
    .card-description {
        font-size: 11px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .cardCounter {
        max-width: 45%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-right: 20px;
        display: flex;
    }

    .card-image {
        width: 50%;
        height: auto;
        border-radius: 10px;
        padding: 10% 10%;
    }

    .card-caption {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-title {
        font-size: 1rem;
    }

    .card-description {
        margin-top: 10px;
        font-family: "Nunito", Sans-serif;
        font-size: 15px;
    }
}
:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

